import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const myCustomTheme = {
  dark: false,
  colors: {
    background: '#f3f7f3',
    surface: '#f3f7f3',
    primary: '#3a5d3b',
    secondary: '#acbcc9',
    accent: '#444b6c',
    'on-primary': '#ffffff',
    'on-secondary': '#0f170f',
    text: '#0f170f',
  },
};

export default createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: myCustomTheme,
    },
    options: { customProperties: true },
  },
});
