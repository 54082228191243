<template>
  <v-footer color="primary" dark>
    <v-container>
      <v-row justify="center" no-gutters>
        <router-link
          v-for="link in normalLinks"
          :key="link.text"
          :to="link.url"
          class="mx-2"
        >
          <v-btn color="secondary" outlined small rounded="xl" variant="text">
            {{ $t(link.text) }}
          </v-btn>
        </router-link>
        <v-btn
          v-for="link in specialLinks"
          :key="link.text"
          class="mx-2"
          color="secondary"
          outlined
          small
          rounded="xl"
          variant="text"
          @click="$emit('show-cookie-settings')"
        >
          {{ $t(link.text) }}
        </v-btn>
        <v-col class="text-center mt-4 footer-text" cols="12">
          {{ new Date().getFullYear() }} — <strong>Respondr</strong>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterSegment',
  data() {
    return {
      links: [
        { text: 'footer.home', url: '/' },
        { text: 'footer.login', url: '/login' },
        { text: 'footer.register', url: '/register' },
        { text: 'footer.privacyPolicy', url: '/privacy-policy' },
        { text: 'footer.termsOfService', url: '/terms-of-service' },
        { text: 'footer.cookieSettings', url: 'cookie-settings' },
      ],
    };
  },
  computed: {
    normalLinks() {
      return this.links.filter(link => link.url !== 'cookie-settings');
    },
    specialLinks() {
      return this.links.filter(link => link.url === 'cookie-settings');
    }
  }
};
</script>

<style scoped>
.v-footer {
  padding: 20px 0;
  background-color: var(--v-primary-base); /* Using Vuetify's primary color */
}

.v-btn {
  font-size: 14px;
  color: var(--v-secondary-base); /* Using Vuetify's secondary color */
}

.v-btn:hover {
  background-color: var(--v-primary-darken-1); /* Slightly darker shade on hover */
}

.footer-text {
  font-size: 14px;
  color: white;
}
</style>
