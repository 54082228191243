import { createStore } from 'vuex';
import { db, auth } from '../firebase';  // Ensure correct path

export default createStore({
  state: {
    user: {
      firstName: '',
      botStatus: '',
      tokens: 0,
      tone: '',
      stars: 0,
      include: '',
      exclude: ''
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    updateUserField(state, { field, value }) {
      state.user[field] = value;
    }
  },
  actions: {
    async fetchUserData({ commit }) {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        console.error("User is not authenticated");
        return;
      }
      try {
        const userDoc = await db.collection('users').doc(userId).get();
        if (userDoc.exists) {
          console.log("User data fetched:", userDoc.data());
          commit('setUser', userDoc.data());
        } else {
          console.error("No user document found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async updateUser({ commit }, { field, value }) {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        console.error("User is not authenticated");
        return;
      }
      try {
        commit('updateUserField', { field, value });
        await db.collection('users').doc(userId).update({
          [field]: value
        });
        console.log(`User data updated: ${field} = ${value}`);
      } catch (error) {
        console.error("Error updating user data:", error);
      }
    }
  }
});
