// Main app
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'; // Import createI18n
import App from './App.vue';
import router from './router';
import store from './store';
import { auth } from './firebase'; // Import Firebase
import '@mdi/font/css/materialdesignicons.css';

// Vuetify
import 'vuetify/styles';

// Import the global CSS file
import './assets/global.css';

// Import Vuetify configuration
import vuetify from './plugins/vuetify'; // Corrected path

// Import language files
import en from './locales/en.json';
import da from './locales/da.json';

// Create i18n instance
const i18n = createI18n({
  legacy: false, // Set to false to use Composition API
  locale: 'en', // Set default locale
  fallbackLocale: 'en', // Set fallback locale
  messages: {
    en,
    da
  }
});

// Add Google Analytics
const addGoogleAnalytics = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-NHJZ9D8S3K';
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-NHJZ9D8S3K');

    // Track initial page load
    gtag('config', 'G-NHJZ9D8S3K', {
      'page_path': window.location.pathname,
    });

    // Track route changes
    router.afterEach((to) => {
      gtag('config', 'G-NHJZ9D8S3K', {
        'page_path': to.path,
      });
    });
  };
};

addGoogleAnalytics();

// Create and mount Vue application
const app = createApp(App);
app.use(store);
app.use(router);
app.use(vuetify);
app.use(i18n); // Use i18n plugin
app.mount('#app');

console.log(auth); // Temporary use of auth to satisfy ESLint
