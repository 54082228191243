import { createRouter, createWebHistory } from 'vue-router';
import { getAuth } from 'firebase/auth';

const HomePage = () => import(/* webpackChunkName: "home" */ '../views/HomePage.vue');
const Register = () => import(/* webpackChunkName: "register" */ '../views/Register.vue');
const Login = () => import(/* webpackChunkName: "login" */ '../views/Login.vue');
const StatusPage = () => import(/* webpackChunkName: "status" */ '../views/StatusPage.vue');
const PricingPage = () => import(/* webpackChunkName: "pricing" */ '../views/PricingPage.vue');
const TrustpilotConfiguration = () => import(/* webpackChunkName: "trustpilot" */ '@/views/TrustpilotConfiguration.vue');
const PrivacyPolicy = () => import(/* webpackChunkName: "privacy" */ '@/views/PrivacyPolicy.vue');
const TermsAndConditions = () => import(/* webpackChunkName: "terms" */ '@/views/TermsAndConditions.vue');

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/register',
    name: 'UserRegister',
    component: Register,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms-of-service',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: Login,
  },
  {
    path: '/status',
    name: 'Status',
    component: StatusPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/pricing',
    name: 'PricingPage',
    component: PricingPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/trustpilot-config',
    name: 'TrustpilotConfiguration',
    component: TrustpilotConfiguration,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = getAuth();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;

  if (requiresAuth && !isAuthenticated) {
    next({ name: 'UserLogin' });
  } else if (isAuthenticated && to.name === 'Login') {
    next({ name: 'Status' });
  } else {
    next();
  }
});

export default router;
