// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
//import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJVrfAC_Fyocb2r6cvXg3w2uSVxDkiD5k",
  authDomain: "trustpilot-bot.firebaseapp.com",
  projectId: "trustpilot-bot",
  storageBucket: "trustpilot-bot.appspot.com",
  messagingSenderId: "819198880870",
  appId: "1:819198880870:web:7edfa407e1ada47a14d1a7",
  measurementId: "G-NHJZ9D8S3K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };