<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>
        <router-link :to="{ name: 'HomePage' }" class="logo-text">
          RESPONDR
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <!-- Language Switcher Menu -->
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn
              icon
              v-bind="props"
              class="mr-4"
              aria-label="Language Switcher"
            >
              <v-icon>mdi-earth</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="locale in availableLocales"
              :key="locale.value"
              @click="handleLanguageChange(locale.value)"
            >
              <v-list-item-title>
                <span class="flag-icon mr-2">{{ locale.flag }}</span>
                {{ locale.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <span class="mr-4 d-none d-md-inline" v-if="user">{{ $t('welcome') }}, {{ user.email }}</span>

        <template v-if="user">
          <v-btn :to="{ name: 'Status' }" color="secondary" outlined class="mr-2">{{ $t('settings') }}</v-btn>
          <v-btn @click="logout" color="secondary" outlined>{{ $t('logOut') }}</v-btn>
        </template>
        <template v-else>
          <v-btn :to="{ name: 'UserLogin' }" color="secondary" outlined class="mr-2">{{ $t('login') }}</v-btn>
          <v-btn :to="{ name: 'UserRegister' }" color="secondary" outlined>{{ $t('register') }}</v-btn>
        </template>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <footer-segment @show-cookie-settings="showCookieSettings" />

    <v-dialog v-model="cookieDialog" max-width="500" aria-label="Cookie Settings Dialog">
      <v-card>
        <v-card-title>{{ $t('cookieSettings') }}</v-card-title>
        <v-card-text>
          <p class="pb-4">{{ $t('cookiePolicy') }}</p>

          <v-list-subheader class="font-weight-black text-high-emphasis">{{ $t('requiredCookies') }}</v-list-subheader>
          <p class="mb-4">{{ $t('requiredCookiesDescription') }}</p>

          <v-list-subheader class="font-weight-black text-high-emphasis">{{ $t('performanceCookies') }}</v-list-subheader>
          <v-switch
            v-model="performance"
            :label="performance ? $t('on') : $t('off')"
            color="green-darken-4"
            density="compact"
            hide-details
            inline
            inset
          ></v-switch>
          <p class="mb-4">{{ $t('performanceCookiesDescription') }}</p>

          <v-list-subheader class="font-weight-black text-high-emphasis">{{ $t('advertisingCookies') }}</v-list-subheader>
          <v-switch
            v-model="advertising"
            :label="advertising ? $t('on') : $t('off')"
            color="green-darken-4"
            density="compact"
            hide-details
            inline
            inset
          ></v-switch>
          <p class="mb-16">{{ $t('advertisingCookiesDescription') }}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center px-6 py-3">
          <v-btn class="flex-grow-1 text-none" color="green-darken-4" rounded="0" variant="plain" @click="declineAllCookies">
            {{ $t('declineAll') }}
          </v-btn>
          <v-btn class="text-white flex-grow-1 text-none" color="green-darken-4" rounded="0" variant="flat" @click="saveAndAcceptCookies">
            {{ $t('saveAndAccept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import FooterSegment from '@/components/FooterSegment.vue';

const LANGUAGE_KEY = 'userLanguage';

export default {
  name: 'App',
  components: {
    FooterSegment,
  },
  data() {
    return {
      user: null,
      cookieDialog: false,
      advertising: true,
      performance: true,
      availableLocales: [
        { value: 'en', text: 'English', flag: '🇺🇸' },
        { value: 'da', text: 'Dansk', flag: '🇩🇰' }
      ]
    };
  },
  created() {
    this.initializeAuth();
    this.loadSavedLanguage();
  },
  methods: {
    initializeAuth() {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        this.user = user;
        this.handleAuthStateChange(user);
      });
    },
    handleAuthStateChange(user) {
      if (user) {
        if (this.$route.name === 'UserLogin' || this.$route.name === 'HomePage') {
          this.$router.push({ name: 'Status' });
        }
      } else if (this.$route.meta.requiresAuth) {
        this.$router.push({ name: 'UserLogin' });
      }
    },
    loadSavedLanguage() {
      const savedLanguage = localStorage.getItem(LANGUAGE_KEY);
      if (savedLanguage) {
        this.$i18n.locale = savedLanguage;
      }
    },
    async logout() {
      const auth = getAuth();
      try {
        await signOut(auth);
        this.user = null;
        this.$router.push('/login');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    },
    showCookieSettings() {
      this.cookieDialog = true;
    },
    handleLanguageChange(lang) {
      console.log('Changing language to:', lang);
      this.$i18n.locale = lang;
      localStorage.setItem(LANGUAGE_KEY, lang);
    },
    declineAllCookies() {
      this.performance = false;
      this.advertising = false;
      this.saveCookiePreferences();
    },
    saveAndAcceptCookies() {
      this.saveCookiePreferences();
    },
    saveCookiePreferences() {
      // Implement the logic to save cookie preferences
      console.log('Saving cookie preferences:', { performance: this.performance, advertising: this.advertising });
      this.cookieDialog = false;
    }
  },
};
</script>

<style scoped>
.logo-text {
  font-size: 1.5rem;
  font-weight: 900;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
}

.v-btn {
  margin-left: 10px;
}

.flag-icon {
  font-size: 1.2rem;
}

@media (max-width: 600px) {
  .logo-text {
    font-size: 1.2rem;
  }

  .v-btn {
    margin-left: 5px;
  }
}

@media (max-width: 400px) {
  .logo-text {
    font-size: 1rem;
  }

  .v-btn {
    margin-left: 2px;
  }
}
</style>
